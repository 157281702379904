import { useCallback, useEffect } from "react";
import throttle from "lodash/throttle.js";

type ScrollProps = {
  hasMore: boolean;
  isLoading: boolean;
  onScrollEvent: () => void;
};

export const useScroll = ({
  hasMore,
  isLoading,
  onScrollEvent,
}: ScrollProps) => {
  const handleScroll = useCallback(
    throttle(() => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.offsetHeight;

      if (scrollY + windowHeight + 1 >= bodyHeight && hasMore && !isLoading) {
        onScrollEvent();
      }
    }, 200),
    [hasMore, isLoading]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
};

// export const useSmoothScroll = () => {};
