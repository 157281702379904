import throttle from "lodash/throttle.js";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBranchesList } from "~/old-app/api/branch";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import BranchCard from "../../Cards/CardBranch";
import NoData from "../../Cards/NoData";
import SectionTitle from "../../Typography/SectionTitle";

const BranchesSection = ({ title, items }) => {
  const { t } = useTranslation();
  const { selectedService } = useConfigSlice();
  const { branches, isLoading, hasMore, page, setPage } = useBranchesList(
    selectedService,
    {
      skip: items?.length > 0,
    }
  );

  const [branchesData, setBranchesData] = useState(items || []);

  const handleScroll = useCallback(
    throttle(() => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.offsetHeight;

      if (scrollY + windowHeight >= bodyHeight && hasMore && !isLoading) {
        setPage((prevPage) => prevPage + 1);
      }
    }, 200),
    [hasMore, isLoading]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (!isLoading && branches?.length > 0 && page === 1) {
      setBranchesData(branches);
    } else if (!isLoading && branches?.length > 0 && page > 1) {
      setBranchesData((prevData) => [...prevData, ...branches]);
    }
  }, [branches, isLoading, page]);

  return (
    <section className="container w-full flex flex-col py-5">
      <header className="w-full">
        <SectionTitle title={title} />
      </header>

      {isLoading ? (
        <div className="flex items-center justify-center my-5">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary-500 border-e-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      ) : branchesData && branchesData.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {branchesData.map((item) => (
            <BranchCard key={item.id} item={item} />
          ))}
        </div>
      ) : (
        <NoData
          title={t("global.noBranchesTitle")}
          subtitle={t("global.noBranchesSubtitle")}
        />
      )}
    </section>
  );
};

export default BranchesSection;

// useEffect(() => {
//   if (total_pages <= page) {
//     setHasMore(false);
//   }
// }, [page, total_pages]);
// useEffect(() => {
//   if (!isLoading) {
//     dispatch(actions.branchesRequest({ page, service_id: service }));
//   }
// }, [page, service]);
