import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { useAddToCartMutation } from "~/old-app/api/cart";
import { useToggleFavoriteMutation } from "~/old-app/api/favorite";
import {
  useAddToInfluencerCollectionMutation,
  useRemoveFromInfluencerCollectionMutation,
} from "~/old-app/api/influencer";
import { ChevronLeftIcon, ChevronRightIcon } from "~/old-app/assets/icons";
import { useCurrency } from "~/old-app/hooks/useCurrency";
import { useParamUpdater } from "~/old-app/hooks/useParamUpdator";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { cls } from "~/old-app/utils/helpers/cls";
import { ProductTags } from "../../Cards/tags";
import StarRating from "../../Rating";
import SectionTitle from "../../Typography/SectionTitle";

export const OffersHomeSection = ({
  card,
  order_by,
  items,
  isLoading,
  order,
  sale,
  title,
  itemsCount,
  breakpoints,
  onSeeAllClick,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { formatPrice } = useCurrency();
  const { accessToken, isInfluencer } = useAuthSlice();
  const isLoggedIn = !!accessToken;
  const [toggleFav] = useToggleFavoriteMutation();
  const [addToInfluencerCollection] = useAddToInfluencerCollectionMutation();
  const [removeFromInfluencerCollection] =
    useRemoveFromInfluencerCollectionMutation();

  const [addToCartMutate, { isLoading: addToCartLoading }] =
    useAddToCartMutation();
  const { params } = useParamUpdater();
  const navigate = useNavigate();

  const addToCartHandler = () => {
    if (isDigitalProduct) {
      navigate(`/${language}/checkout/${urlParam}`);
      return;
    }
    addToCartMutate({
      id: product?.id,
      qty: 1,
      order_type: "web",
      delete_old_cart: false,
      influencer_id: params?.influencer,
    })
      .unwrap()
      .then((data) => {
        toast.success(data.message);
      });
  };

  const [resultItems, setResultItems] = useState([]);

  useEffect(() => {
    let resultItems = [];
    let subItems = [];
    for (let i = 0; i < items.length; i++) {
      if (resultItems?.[resultItems.length - 1]?.length === 2) {
        resultItems.push([items[i]]);
        subItems = [];
      } else {
        if (subItems.length === 0) {
          subItems.push(items[i]);

          if (i === items.length - 1) {
            resultItems.push(subItems);
          }
        } else {
          subItems.push(items[i]);
          resultItems.push(subItems);
          subItems = [];
        }
      }
    }
    setResultItems(resultItems);
  }, [items]);

  const slide = (direction) => {
    const container = document.getElementById("offers-home-section");
    if (direction === "NEXT") {
      container.scrollBy({ left: container.offsetWidth, behavior: "smooth" });
    } else if (direction === "PREV") {
      container.scrollBy({ left: -container.offsetWidth, behavior: "smooth" });
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      slide("NEXT");
    },
    onSwipedRight: () => {
      slide("PREV");
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const OfferCard = ({
    item: product,
    showFav,
    isBig = false,
    imgW = 12,
    imgH = "",
  }) => {
    const hasTags = !!product?.ribbon;
    const isDigitalProduct = !!product?.is_digital;

    const favoriteToggleHandler = () => {
      if (isInfluencer) {
        (product?.favorite
          ? removeFromInfluencerCollection(product.id)
          : addToInfluencerCollection(product.id)
        )
          .unwrap()
          .then((data) => {
            toast.success(data.message);
          });
      } else {
        toggleFav({
          item_id: product.id,
          type,
        })
          .unwrap()
          .then((data) => {
            toast.success(data.message);
          });
      }
    };

    const primaryImage =
      product?.media?.find((media) => media.is_primary === 1) || {};

    const urlParam = product?.slug || product?.id;

    return (
      <div
        className={cls("w-full h-[15rem] relative", {
          "h-[30.5rem]": isBig,
        })}
      >
        <div className="absolute top-1 start-1 ">
          <ProductTags product={product} />
        </div>
        <div className="relative overflow-hidden group flex flex-col min-w-0 break-words bg-white w-full rounded-lg gap-0 border border-gray-80 h-full">
          <Link
            to={`/${language}/products/${urlParam}`}
            className={`relative flex items-center justify-center rounded-lg w-full  ${
              isBig ? "h-[25.5rem]" : "h-[9rem]"
            }`}
            onClick={(e) => e.stopPropagation()}
          >
            <img
              {...handlers}
              loading="lazy"
              alt={primaryImage.name}
              src={primaryImage.url ? primaryImage.url : product?.image}
              className={`w-full  object-cover align-middle ${
                isBig ? "h-[22rem]" : "h-[9rem]"
              }`}
            />
          </Link>
          <div
            className={cls("p-4  flex flex-col min-h-[8.375rem]", {
              "pt-2": hasTags,
            })}
          >
            {product?.out_ot_stock && (
              <h5 className={`text-sm text-error-500 capitalize font-bold`}>
                {t("cart.outOfStock")}
              </h5>
            )}

            <blockquote>
              <div className="flex justify-between items-center gap-1">
                <Link to={`/${language}/products/${urlParam}`}>
                  <h3
                    className={`${
                      isBig ? "text-sm line-clamp-2" : "text-xs line-clamp-1"
                    } text-secondary-950 leading-3 capitalize overflow-hidden `}
                  >
                    {product?.name}
                  </h3>
                </Link>

                {/* <div className="flex items-center">
                  <span className="text-xs text-gray-500">
                    ({product?.rating_average || 0})
                  </span>
                  <StarIcon className="text-primary-500" size={16} />
                </div> */}
              </div>

              <div className="flex gap-2 items-center mb-1">
                {product?.rating_average !== "undefined" && (
                  <StarRating
                    size="lg"
                    rating={parseFloat(product?.rating_average)}
                  />
                )}
                {product?.rating_count !== "undefined" && (
                  <span className="text-xxs text-typo-700">
                    ({product?.rating_count})
                  </span>
                )}
              </div>

              {/* <div className="text-xs flex gap-3  text-gray-500 leading-3 ">
                {t("global.price")}
              </div> */}
              <div className="text-sm flex gap-2 leading-3  flex-row">
                {!!product?.on_sale ? (
                  <>
                    <span className="whitespace-nowrap text-sm text-secondary-950 font-semibold ">
                      {formatPrice(product?.sale_price)}
                    </span>
                    <span className="text-gray-500 line-through text-xs">
                      {formatPrice(product?.price)}
                    </span>
                  </>
                ) : (
                  <>{formatPrice(product?.price)}</>
                )}
              </div>
            </blockquote>
            {/* {isLoggedIn ? (
              <>
                {product?.favorite ? (
                  <div className="absolute top-2 end-2 flex items-center justify-end p-1 border border-gray-300 rounded-full bg-gray-100">
                    <FavFilledIcon
                      onClick={favoriteToggleHandler}
                      className="text-primary-400 cursor-pointer"
                      size={18}
                    />
                  </div>
                ) : (
                  <div className="absolute top-2 end-2 flex items-center justify-end p-1 border border-gray-300 rounded-full bg-gray-100">
                    <FavIcon
                      onClick={favoriteToggleHandler}
                      className="text-gray-400 cursor-pointer"
                      size={18}
                    />
                  </div>
                )}
              </>
            ) : null} */}

            {/* {isLoggedIn && (
          <div
            className="absolute h-8 w-8 rounded-full flex items-center justify-center bg-primary-500 text-white bottom-2  end-2 cursor-pointer"
            onClick={addToCartHandler}
          >
            {isLoading ? (
              <div className="flex w-full justify-center items-center py-10">
                <SpinnerIcon
                  className="animate-spin text-2xl text-primary"
                  size={14}
                />
              </div>
            ) : (
              <Plus size={16} />
            )}
          </div>
        )} */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="container w-full flex flex-col py-5 relative">
      <header className="w-full flex items-center justify-between">
        <SectionTitle title={title} />

        {!!onSeeAllClick && (
          <div
            onClick={onSeeAllClick}
            className={cls(
              "text-primary text-sm font-semibold cursor-pointer text-primary-500",
              {
                "mb-4": !title,
              }
            )}
          >
            {t("global.seeAll")}
          </div>
        )}
      </header>

      <div
        className="flex flex-row gap-2 overflow-x-auto hide-scrollbar relative"
        id="offers-home-section"
        {...handlers}
      >
        {resultItems.map((item, index) =>
          item.length === 2 ? (
            <div
              key={`offer-${index}`}
              className="flex flex-col col-span-2 justify-between gap-[0.5rem]"
            >
              {item.map((offer) => (
                <div
                  className="flex-none w-[14rem]"
                  key={`offer-sub-${offer.id}-${index}`}
                >
                  <OfferCard item={offer} showFav />
                </div>
              ))}
            </div>
          ) : (
            <div
              key={`offer-${index}`}
              className={cls("col-span-3 flex-none w-[20rem]")}
            >
              <OfferCard item={item?.[0]} showFav isBig />
            </div>
          )
        )}
      </div>

      <div
        className="cursor-pointer h-10 w-10 bg-primary-500 rounded-full text-white flex items-center justify-center absolute z-50 start-0 inset-y-1/2"
        onClick={() => {
          const isRtl = document.documentElement.dir === "rtl";
          isRtl ? slide("PREV") : slide("NEXT");
        }}
      >
        <ChevronRightIcon className="transform ltr:rotate-180" />
      </div>
      <div
        className="cursor-pointer h-10 w-10 bg-primary-500 rounded-full text-white flex items-center justify-center absolute z-50 end-0 inset-y-1/2"
        onClick={() => {
          const isRtl = document.documentElement.dir === "rtl";
          isRtl ? slide("NEXT") : slide("PREV");
        }}
      >
        <ChevronLeftIcon className="transform ltr:rotate-180" />
      </div>
    </section>
  );
};
