import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useService } from "~/old-app/api/service";
import { InfiniteScroll } from "~/old-app/components/infinite-scroll";
import Spinner from "~/old-app/components/Spinner";
import BranchesSection from "~/old-app/components/Views/Home/BranchesSection";
import FullWidthCarousel from "~/old-app/components/Views/Home/FullWidthCarousel";
import { OffersHomeSection } from "~/old-app/components/Views/Home/offers-home-sction";
import ProductsSection from "~/old-app/components/Views/Home/ProductsSection";

import Section from "~/old-app/components/Views/Home/Section";
import { useGoogleTag } from "~/old-app/hooks/useGoogleTag";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { GtmEvents, sendGtmEvent } from "~/old-app/utils/helpers/google-tags";

const RenderBlock = ({ block, index, isLoading }) => {
  const { selectedService } = useConfigSlice();
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  switch (block.type) {
    case "sliders":
      if (block.style === "full-width") {
        return (
          <Section
            key={index}
            title={block.title}
            items={block.items}
            itemsCount={block?.items?.length}
            showNavigation={block?.items?.length > 1}
            card="sliders"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              480: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            }}
          />
        );
      } else if (block.style === "banner") {
        return <FullWidthCarousel key={index} slides={block.items} />;
      }
      break;

    // case "sliders":
    //   return (
    //     <Section
    //       key={index}
    //       title={block.title}
    //       items={block.items}
    //       itemsCount={block.items.length}
    //       card="sliders"
    //       breakpoints={{
    //         320: {
    //           slidesPerView: 1,
    //           spaceBetween: 20,
    //         },
    //         480: {
    //           slidesPerView: 1,
    //           spaceBetween: 30,
    //         },
    //         640: {
    //           slidesPerView: 1,
    //           spaceBetween: 20,
    //         },
    //         1024: {
    //           slidesPerView: 1,
    //           spaceBetween: 20,
    //         },
    //       }}
    //     />
    //   );

    case "offer-collections":
      return (
        <Section
          key={index}
          title={block.title}
          items={block.items}
          itemsCount={block.items.length}
          isLoading={isLoading}
          card="offer-collections"
          onSeeAllClick={() => {
            navigate(`/${language}/offers`);
          }}
          breakpoints={{
            320: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 1.2,
              spaceBetween: 14,
            },
            640: {
              slidesPerView: 2.2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3.2,
              spaceBetween: 40,
            },
          }}
        />
      );
    // return  <FullWidthCarousel key={index} slides={block.items} />;
    case "branch-categories":
      return (
        <Section
          key={index}
          title={block.title}
          items={block.items}
          itemsCount={9}
          card="category"
        />
      );

    case "influencers": {
      return (
        <Section
          key={index}
          title={block.title}
          items={block.items}
          // itemsCount={9}
          isLoading={isLoading}
          breakpoints={{
            320: {
              slidesPerView: 3.2,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 3.5,
              spaceBetween: 14,
            },
            640: {
              slidesPerView: 5.2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 7.2,
              spaceBetween: 40,
            },
          }}
          card="influencer"
        />
      );
    }
    case "featured-links": {
      return (
        <Section
          key={index}
          title={block.title}
          items={block.items}
          itemsCount={block.items.length}
          isLoading={isLoading}
          breakpoints={{
            320: {
              slidesPerView: 2.2,
              spaceBetween: 16,
            },
            480: {
              slidesPerView: 2.2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 3.5,
              spaceBetween: 30,
            },

            768: {
              slidesPerView: 4.5,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 5.5,
              spaceBetween: 40,
            },

            1280: {
              slidesPerView: 6.5,
              spaceBetween: 50,
            },

            1536: {
              slidesPerView: 7.5,
              spaceBetween: 60,
            },
          }}
          card="featured-links"
        />
      );
    }

    case "offers":
    case "selected-offers":
      return (
        // <Section
        <OffersHomeSection
          key={index}
          title={block.title}
          items={block.items}
          itemsCount={2.2}
          card="offer"
        />
      );
    // <OffersHomeSection
    //   key={index}
    //   title={block.title}
    //   items={block.items}
    //   itemsCount={2.5}
    //   card="offer"
    // />

    case "product-categories":
    case "selected-categories":
      return (
        <Section
          key={index}
          title={block.title}
          items={block.items}
          card="category"
          breakpoints={{
            320: {
              slidesPerView: 2.4,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 3.4,
              spaceBetween: 14,
            },
            640: {
              slidesPerView: 5.4,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 7.4,
              spaceBetween: 40,
            },
          }}
        />
      );
    // case "selected-branches":
    //   return (
    //     <Section
    //       key={index}
    //       title={block.title}
    //       items={block.items}
    //       itemsCount={4.5}
    //       card="serviceBranch"
    //     />
    //   );
    case "brands":
    case "selected-brands":
      return (
        <Section
          key={index}
          title={block.title}
          items={block.items}
          itemsCount={5.5}
          card="brand"
          breakpoints={{
            320: {
              slidesPerView: 2.4,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 3.4,
              spaceBetween: 14,
            },
            640: {
              slidesPerView: 4.7,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 6.4,
              spaceBetween: 40,
            },
          }}
        />
      );
    case "selected-products":
      return (
        <Section
          key={index}
          title={block.title}
          items={block.items}
          card="serviceProduct"
          breakpoints={{
            320: {
              slidesPerView: 2.2,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2.2,
              spaceBetween: 12,
            },
            640: {
              slidesPerView: 3.2,
              spaceBetween: 14,
            },
            1024: {
              slidesPerView: 4.2,
              spaceBetween: 16,
            },

            1280: {
              slidesPerView: 5.2,
              spaceBetween: 18,
            },
          }}
        />
      );
    case "branches":
    case "selected-branches":
      return (
        <BranchesSection
          key={index}
          title={block.title}
          service={selectedService}
          items={block.items}
        />
      );
    case "products":
      return <ProductsSection key={index} title={block.title} />;
    default:
      return null;
  }
};

export default function Home() {
  const { serviceDetails, remoteConfig } = useConfigSlice();
  const title = serviceDetails?.name || "";
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);

  const { service, blocks, isLoading, isFetching } = useService(1, page);
  const { sendGoogleTagEvent } = useGoogleTag();

  useEffect(() => {
    window.scrollTo(0, 0);

    sendGoogleTagEvent({
      event: GtmEvents.Home,
      dataLayer: {},
    });
  }, []);

  if (isLoading || !blocks?.length) {
    return (
      <div className="flex w-full min-h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const renderBlocks = () => {
    if (blocks) {
      return blocks.map((block, index) => (
        <RenderBlock block={block} index={index} key={`block-${index}`} />
      ));
    }
    return null;
  };

  return (
    <div>
      <h1 className="hidden">{title}</h1>

      <InfiniteScroll
        data={blocks || []}
        onNext={() => {
          setPage((p) => p + 1);
        }}
        hasMore
        isFetching={isFetching}
      >
        {renderBlocks()}
      </InfiniteScroll>

      <div className="flex flex-col container gap-2 my-6 ">
        <h3 className="text-xl font-semibold text-primary-500">
          {t("footer.whoWeAre")}
        </h3>

        <div
          className=" "
          dangerouslySetInnerHTML={{
            __html: showMore
              ? remoteConfig?.footer?.who_we_are
              : remoteConfig?.footer?.who_we_are?.slice(0, 200) + "...",
          }}
        ></div>
        <button
          onClick={() => setShowMore((s) => !s)}
          className="text-primary-500 underline"
        >
          {showMore ? t("global.readLess") : t("global.readMore")}
        </button>
      </div>
    </div>
  );
}
