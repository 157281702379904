// https://dev.to/amin_deraiya/react-detect-div-reach-top-and-bottom-1fjp

import { useTranslation } from "react-i18next";
import { useProducts } from "~/old-app/api/product";
import { useScroll } from "~/old-app/hooks/useScroll";
import ProductCard from "../../Cards/CardProduct";
import NoData from "../../Cards/NoData";
import SectionTitle from "../../Typography/SectionTitle";
import { useSearchProducts } from "~/old-app/api/search";

const ProductsSection = ({ title }) => {
  const { t } = useTranslation();
  const { hasMore, isLoading, products, setPage, page, isFetching } =
    useSearchProducts();

  const onScrollEvent = () => {
    if (hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useScroll({ hasMore, isLoading, onScrollEvent });

  // const { products, isLoading, total_pages } = useSelector(
  //   (state) => state.Products.serviceProducts
  // );
  // const [page, setPage] = useState(1);
  // const [productsData, setProductsData] = useState([]);
  // const [hasMore, setHasMore] = useState(true);

  // const handleScroll = useCallback(
  //   throttle(() => {

  //     const scrollY = window.scrollY;
  //     const windowHeight = window.innerHeight;
  //     const bodyHeight = document.body.offsetHeight;

  //     if (scrollY + windowHeight >= bodyHeight && hasMore && !isLoading) {
  //       setPage((prevPage) => prevPage + 1);

  //     }
  //   }, 200),
  //   [hasMore, isLoading]
  // );

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [handleScroll]);

  // useEffect(() => {
  //   if (!isLoading) {
  //     dispatch(actions.productsRequest({ page, service_id: service }));
  //   }
  // }, [page, service]);

  // useEffect(() => {
  //   if (!isLoading && products?.length > 0 && page === 1) {
  //     setProductsData(products);
  //   } else if (!isLoading && products?.length > 0 && page > 1) {
  //     setProductsData((prevData) => [...prevData, ...products]);
  //   }
  // }, [products, isLoading, page]);

  // useEffect(() => {
  //   if (total_pages <= page) {
  //     setHasMore(false);
  //   }
  // }, [page, total_pages]);

  return (
    <section className="container w-full flex flex-col py-5">
      <header className="w-full">
        <SectionTitle title={title} />
      </header>

      {isLoading ? (
        <div className="flex items-center justify-center my-5">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-primary-500 border-e-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      ) : products && products.length > 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          {products.map((item) => (
            <ProductCard key={`product-${item.id}`} item={item} />
          ))}
        </div>
      ) : (
        <NoData
          title={t("global.noProductsTitle")}
          subtitle={t("global.noProductsSubtitle")}
        />
      )}
    </section>
  );
};

export default ProductsSection;
