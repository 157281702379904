import { useTranslation } from "react-i18next";
import { DefaultImage } from "~/old-app/constants";

const FullWidthCarousel = ({ slides }) => {
  // const User = useSelector((state) => state.User);
  // const {
  //   t,
  //   i18n: { language },
  // } = useTranslation();
  // function convertDeepLink(deepLink) {

  //   // Extract the path and ID from the deep link
  //   const regex = /sinbad:\/\/\/(product-details|branch-details)\?id=([\w-]+)/;
  //   const match = deepLink.match(regex);

  //   if (!match) {
  //     return "Invalid deep link";
  //   }

  //   const type = match[1]; // 'product-details' or 'branch-details'
  //   const id = match[2]; // Extracted ID

  //   // Construct the correct URL based on the deep link type
  //   if (type === "product-details") {
  //     return `/${language}/products/${id}`;
  //   } else if (type === "branch-details") {
  //     return `/${language}/branches/${id}`;
  //   } else {
  //     return "Unknown deep link type";
  //   }
  // }

  return (
    <section className="container my-5">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        {slides.slice(0, 21).map((slide) => (
          <a
            key={slide.id}
            href={slide.link}
            target="_blank"
            // href={convertDeepLink(slide.link)}
            className="w-full cursor-pointer"
          >
            <img
              loading="lazy"
              key={`slide-${slide.id}`}
              className="h-full object-contain w-full rounded-xl"
              src={slide.image || DefaultImage}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = DefaultImage;
              }}
            />
          </a>
        ))}
      </div>
    </section>
  );
};

export default FullWidthCarousel;
